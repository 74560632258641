import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  BooleanField,
  EditButton,
} from 'react-admin';

import { AppExporter, AppPagination, SearchFilter } from '../../components';

export default function (props) {
  return (
    <List
      {...props}
      sort={{ field: 'id', order: 'DESC' }}
      bulkActionButtons={false}
      export={AppExporter}
      filters={<SearchFilter />}
      pagination={<AppPagination />}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="title" />
        <NumberField source="days" />
        <BooleanField source="is_enable" />
        <DateField source="date_created" showTime />
        <EditButton />
      </Datagrid>
    </List>
  );
}
