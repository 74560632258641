import {
  Edit,
  SimpleForm,
  NumberInput,
  SelectInput,
  TextInput,
  ReferenceInput,
} from 'react-admin';

import { AppToolbar } from '../../components';

import { TransactionStatus, TransactionTypes } from './constants';
import { AccountAutocompleteInput } from './components';

export default function TransactionEdit(props) {
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm toolbar={<AppToolbar />} redirect={false}>
        <ReferenceInput
          disabled={true}
          label="Account"
          source="account"
          reference="accounts"
          filter={{ is_active: true, searchFields: 'username' }}
          sort={{ field: 'id', order: 'ASC' }}
          filterToQuery={searchText => ({ search: searchText })}
        >
          <AccountAutocompleteInput />
        </ReferenceInput>
        <NumberInput disabled={true} source="amount" />
        <SelectInput
          disabled={true}
          source="transaction_type"
          choices={TransactionTypes}
        />
        <SelectInput
          source="transaction_status"
          choices={TransactionStatus}
          defaultValue={1}
        />
        <TextInput source="remark" multiline fullWidth />
      </SimpleForm>
    </Edit>
  );
}
