export const TransactionStatus = [
  { id: 1, name: 'authorized' },
  { id: 2, name: 'voided' },
  { id: 3, name: 'pending' },
];

export const TransactionTypes = [
  { id: 1, name: 'admin_transaction' },
  { id: 2, name: 'transfer_transaction' },
  { id: 3, name: 'transfer_fee' },
  { id: 4, name: 'withdrawal_transaction' },
  { id: 5, name: 'withdrawal_fee' },
  { id: 6, name: 'deposit_transaction' },
  { id: 7, name: 'shares_transaction' },
  { id: 8, name: 'sponsor_bonus' },
  { id: 9, name: 'trading_bonus' },
  { id: 10, name: 'tiering_bonus' },
  { id: 11, name: 'stake_purchase_transaction' },
  { id: 12, name: 'stake_topup_transaction' },
  { id: 13, name: 'stake_upgrade_transaction' },
];
