import { Admin, fetchUtils, Resource } from 'react-admin';
import { createTheme } from '@material-ui/core/styles';

import { authProvider, drfProvider } from './providers';

import users from './sections/users';
import accounts from './sections/accounts';
import transactions from './sections/transactions';
import banks from './sections/banks';
import referrals from './sections/referrals';
import referralBonusSettings from './sections/referralBonusSettings';
import referralBonusTransactions from './sections/referralBonusTransactions';
import passwords from './sections/passwords';
import withdrawalSettings from './sections/withdrawalSettings';
import withdrawalBanks from './sections/withdrawalBanks';
import termSettings from './sections/termSettings';
import terms from './sections/terms';
import contracts from './sections/contracts';
import stakes from './sections/stakes';
import invests from './sections/invests';
import investBonusTransaction from './sections/investBonusTransactions';
import goldmineBonusSettings from './sections/goldmineBonusSettings';
import goldmineBonusTransactions from './sections/goldmineBonusTransactions';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF7F27',
    },
    secondary: {
      main: '#FFA500',
    },
  },
});

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

export default function App() {
  return (
    <Admin
      theme={theme}
      authProvider={authProvider}
      dataProvider={drfProvider(process.env.REACT_APP_API_HOST, httpClient)}
    >
      <Resource name="users/switch" edit={users.edit} />
      <Resource name="users" {...users} />
      <Resource
        name="referrals/bonus/settings"
        {...referralBonusSettings}
        options={{ label: 'Referral bonus settings' }}
      />
      <Resource
        name="referrals/bonus"
        {...referralBonusTransactions}
        options={{ label: 'Referral bonus transactions' }}
      />
      <Resource name="referrals" {...referrals} />
      <Resource name="accounts" {...accounts} />
      <Resource name="transactions" {...transactions} />
      <Resource name="stakes" {...stakes} />
      <Resource
        name="invests/bonus"
        {...investBonusTransaction}
        options={{ label: 'Invest bonus transactions' }}
      />
      <Resource name="invests" {...invests} />
      {/* <Resource
        name="goldmine/bonus"
        {...goldmineBonusTransactions}
        options={{ label: 'Goldmine bonus transactions' }}
      />
      <Resource
        name="goldmine/settings"
        {...goldmineBonusSettings}
        options={{ label: 'Goldmine bonus settings' }}
      /> */}
      <Resource
        name="terms/settings"
        {...termSettings}
        options={{ label: 'Term settings' }}
      />
      <Resource name="terms" {...terms} />
      <Resource name="contracts" {...contracts} />
      <Resource name="banks" {...banks} />
      <Resource
        name="withdrawals/settings"
        {...withdrawalSettings}
        options={{ label: 'Withdrawal settings' }}
      />
      <Resource
        name="withdrawals/banks"
        {...withdrawalBanks}
        options={{ label: 'Withdrawal banks' }}
      />
      <Resource name="passwords" {...passwords} />
    </Admin>
  );
}
