import { FilterList, FilterListItem } from 'react-admin';

import { InvestStatus } from './constants';

export const StatusFilter = () => (
  <FilterList label="Status">
    {InvestStatus.map(item => (
      <FilterListItem label={item.name} value={{ invest_status: item.id }} />
    ))}
  </FilterList>
);
