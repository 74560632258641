import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  DateInput,
  SelectInput,
} from 'react-admin';

import { AppToolbar } from '../../components';

import { StakeStatus } from './constants';

export default function (props) {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<AppToolbar />} redirect={false}>
        <TextInput disabled source="id" />
        <ReferenceInput disabled source="user" reference="users">
          <AutocompleteInput optionText="username" />
        </ReferenceInput>
        <ReferenceInput disabled source="term" reference="terms">
          <AutocompleteInput optionText="title" />
        </ReferenceInput>
        <NumberInput disabled source="amount" />
        <NumberInput disabled source="shares_amount" />
        <NumberInput disabled source="total_amount" />
        <DateInput disabled source="date_created" />
        <DateInput source="start_date" />
        <DateInput source="end_date" />
        <SelectInput source="stake_status" choices={StakeStatus} />
      </SimpleForm>
    </Edit>
  );
}
