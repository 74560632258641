import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  ReferenceField,
  SelectField,
  EditButton,
} from 'react-admin';
import { Card, CardContent, withStyles } from '@material-ui/core';

import { AppExporter, AppPagination, SearchFilter } from '../../components';

import { StakeStatus } from './constants';
import { StatusFilter } from './filters';

const SidebarCard = withStyles(theme => ({
  root: {
    order: -1, // display on the left rather than on the right of the list
    width: '15em',
    marginRight: '0.3em',
  },
}))(Card);

const FilterSidebar = () => (
  <SidebarCard>
    <CardContent>
      <StatusFilter />
    </CardContent>
  </SidebarCard>
);

export default function (props) {
  return (
    <List
      {...props}
      sort={{ field: 'id', order: 'DESC' }}
      bulkActionButtons={false}
      export={AppExporter}
      filters={<SearchFilter />}
      pagination={<AppPagination />}
      aside={<FilterSidebar />}
    >
      <Datagrid>
        <TextField source="id" />
        <ReferenceField
          source="user"
          reference="users"
          sortBy="id"
          link={false}
        >
          <TextField source="username" />
        </ReferenceField>
        <ReferenceField
          source="term"
          reference="terms"
          sortBy="id"
          link={false}
        >
          <TextField source="title" />
        </ReferenceField>
        <NumberField source="amount" />
        <NumberField source="shares_amount" />
        <NumberField source="total_amount" />
        <DateField source="start_date" />
        <DateField source="end_date" />
        <SelectField source="stake_status" choices={StakeStatus} />
        <EditButton />
      </Datagrid>
    </List>
  );
}
