import { FilterList, FilterListItem } from 'react-admin';

import { StakeStatus } from './constants';

export const StatusFilter = () => (
  <FilterList label="Status">
    {StakeStatus.map(item => (
      <FilterListItem label={item.name} value={{ stake_status: item.id }} />
    ))}
  </FilterList>
);
