import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
} from 'react-admin';

import { AppToolbar } from '../../components';

import { ContractStatus } from './constants';

export default function (props) {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<AppToolbar />} redirect={false}>
        <TextInput disabled source="id" />
        <ReferenceInput source="term" reference="terms">
          <AutocompleteInput optionText="days" />
        </ReferenceInput>
        <TextInput source="code" />
        <TextInput source="title" />
        <NumberInput source="roi_rate_from" />
        <NumberInput source="roi_rate_to" />
        <NumberInput source="share_allowance_rate" />
        <NumberInput source="bonus_income_cap_rate" />
        <NumberInput source="minimum_amount" />
        <NumberInput source="amount_multiple" />
        <NumberInput source="roi_daily_rate" />
        <SelectInput source="contract_status" choices={ContractStatus} />
      </SimpleForm>
    </Edit>
  );
}
