import {
  List,
  Datagrid,
  TextField,
  DateField,
  SelectField,
  EditButton,
  ReferenceField,
} from 'react-admin';
import { Card, CardContent, withStyles } from '@material-ui/core';

import { AppExporter, AppPagination } from '../../components';

import { BankStatus } from './constants';
import { BankStatusFilter } from './filters';

const SidebarCard = withStyles(theme => ({
  root: {
    order: -1, // display on the left rather than on the right of the list
    width: '15em',
    marginRight: '0.3em',
  },
}))(Card);

const FilterSidebar = () => (
  <SidebarCard>
    <CardContent>
      <BankStatusFilter />
    </CardContent>
  </SidebarCard>
);

export default function BankList(props) {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      export={AppExporter}
      sort={{ field: 'id', order: 'DESC' }}
      pagination={<AppPagination />}
      aside={<FilterSidebar />}
    >
      <Datagrid>
        <TextField source="id" />
        <ReferenceField
          source="user"
          reference="users"
          sortBy="id"
          link={false}
        >
          <TextField source="username" />
        </ReferenceField>
        <TextField source="account_name" />
        <TextField source="account_number" />
        <TextField source="bank_name" />
        <DateField source="date_created" showTime />
        <SelectField source="bank_status" choices={BankStatus} />
        <EditButton />
      </Datagrid>
    </List>
  );
}
