import { FilterList, FilterListItem } from 'react-admin';

import { ContractStatus } from './constants';

export const ContractStatusFilter = () => (
  <FilterList label="Contract status">
    {ContractStatus.map(item => (
      <FilterListItem label={item.name} value={{ contract_status: item.id }} />
    ))}
  </FilterList>
);
