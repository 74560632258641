import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  NumberInput,
} from 'react-admin';

import { AppToolbar } from '../../components';

import { settingsTypes } from './constants';

export default function (props) {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<AppToolbar />} redirect={false}>
        <TextInput disabled source="id" />
        <SelectInput disabled source="settings_type" choices={settingsTypes} />
        <TextInput disabled source="description" />
        <NumberInput source="value" />
      </SimpleForm>
    </Edit>
  );
}
