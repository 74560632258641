import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  SelectInput,
} from 'react-admin';

import { AppToolbar } from '../../components';
import { Status } from './constants';

export default function (props) {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<AppToolbar />} redirect={false}>
        <TextInput disabled source="id" />
        <TextInput disabled source="invest_contract_code" />
        <TextInput disabled source="invest_contract_title" />
        <TextInput disabled source="invest_stake_user_username" />
        <NumberInput disabled source="amount" />
        <NumberInput disabled source="rate" />
        <NumberInput disabled source="bonus" />
        <SelectInput source="status" choices={Status} />
      </SimpleForm>
    </Edit>
  );
}
