import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  BooleanInput,
} from 'react-admin';

import { AppToolbar } from '../../components';

export default function (props) {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<AppToolbar />} redirect={false}>
        <TextInput disabled source="id" />
        <TextInput source="title" />
        <NumberInput source="days" />
        <BooleanInput source="is_enable" />
      </SimpleForm>
    </Edit>
  );
}
