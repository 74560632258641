import { Edit, SimpleForm, TextInput, NumberInput } from 'react-admin';

import { AppToolbar } from '../../components';

export default function (props) {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<AppToolbar />} redirect={false}>
        <TextInput disabled source="id" />
        <NumberInput disabled source="term_days" />
        <TextInput disabled source="term_title" />
        <NumberInput source="rate" />
      </SimpleForm>
    </Edit>
  );
}
