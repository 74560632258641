import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  BooleanInput,
} from 'react-admin';

import { AppToolbar } from '../../components';

export default function (props) {
  return (
    <Create {...props}>
      <SimpleForm toolbar={<AppToolbar />} redirect={false}>
        <TextInput source="title" />
        <NumberInput source="days" />
        <BooleanInput source="is_enable" />
      </SimpleForm>
    </Create>
  );
}
