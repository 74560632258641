import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  EditButton,
  ReferenceField,
  SelectField,
} from 'react-admin';
import { Card, CardContent, withStyles } from '@material-ui/core';

import { AccountTypeFilter } from '../accounts/filters';
import { AccountTypes } from '../accounts/constants';

import { AppExporter, SearchFilter, AppPagination } from '../../components';
import { TransactionStatusFilter } from './filters';
import { TransactionStatus, TransactionTypes } from './constants';

const SidebarCard = withStyles(theme => ({
  root: {
    order: -1, // display on the left rather than on the right of the list
    width: '15em',
    marginRight: '0.3em',
  },
}))(Card);

const FilterSidebar = () => (
  <SidebarCard>
    <CardContent>
      <AccountTypeFilter />
      <TransactionStatusFilter />
    </CardContent>
  </SidebarCard>
);

export default function TransactionList(props) {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      export={AppExporter}
      filters={<SearchFilter />}
      sort={{ field: 'id', order: 'DESC' }}
      pagination={<AppPagination />}
      aside={<FilterSidebar />}
    >
      <Datagrid>
        <TextField source="id" />
        <ReferenceField
          source="user_id"
          reference="users"
          sortBy="id"
          link={false}
        >
          <TextField source="username" />
        </ReferenceField>
        <SelectField source="account_type" choices={AccountTypes} />
        <NumberField source="amount" />
        <DateField source="transaction_date" showTime />
        <SelectField source="transaction_status" choices={TransactionStatus} />
        <SelectField source="transaction_type" choices={TransactionTypes} />
        <EditButton />
      </Datagrid>
    </List>
  );
}
