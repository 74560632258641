import { List, Datagrid, TextField, DateField, EditButton } from 'react-admin';

import { AppExporter, AppPagination, SearchFilter } from '../../components';

export default function ReferralList(props) {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      export={AppExporter}
      filters={<SearchFilter />}
      sort={{ field: 'id', order: 'DESC' }}
      pagination={<AppPagination />}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="referrer_username" />
        <TextField source="referred_username" />
        <TextField source="referred_last_name" />
        <TextField source="referred_first_name" />
        <DateField source="data_created" showTime />
        <EditButton />
      </Datagrid>
    </List>
  );
}
